import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionComponents() {
  return (
    <>
      <div
      className="section section-components section-dark"
      id="hackathons"
      >
        <Row>
          <Col lg="6" md="12">
            <div className="image-container">
              <img
                alt="..."
                className="components-macbook"
                src={
                  require("assets/img/presentation-page/laptop-basic.png")
                }
              />
              <img
                alt="..."
                className="table-img"
                src={require("assets/img/presentation-page/table.jpg")}
              />
              <img
                alt="..."
                className="share-btn-img"
                src={
                  require("assets/img/presentation-page/share-btn.png")
                }
              />
              <img
                alt="..."
                className="coloured-card-btn-img"
                src={
                  require("assets/img/presentation-page/coloured-card-with-btn.jpg")
                }
              />
              <img
                alt="..."
                className="coloured-card-img"
                src={
                  require("assets/img/presentation-page/coloured-card.png")
                }
              />
              <img
                alt="..."
                className="social-img"
                src={
                  require("assets/img/presentation-page/social-row.png")
                }
              />
              <img
                alt="..."
                className="pin-btn-img"
                src={
                  require("assets/img/presentation-page/pin-btn.png")
                }
              />
            </div>
          </Col>
          <Col className="ml-auto mr-auto" lg="4" md="10">
            <Container className="basic-container">
              <h3 className="title">Hackathons!</h3>
              <h6 className="category">We love competing in them</h6>
              <h5 className="description">
                We're constantly looking to compete in global hackathons to put
                our skills to the test and engage and learn from the global coding
                community. We especially strive to engineer solutions that benefit communities
                around the world! Follow us on social media to find out what Hackathons we're currently
                competing in.
              </h5>
            </Container>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SectionComponents;

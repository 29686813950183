import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionContent() {
  return (
    <>
      <div id="tms">
        <div className="section section-content section-gray">
          <Container>
            <Row>
              <Col md="7">
                <div className="image-container">
                  <img
                    alt="..."
                    className="img"
                    src={
                      require("assets/img/presentation-page/ipad-content-2.png")
                    }
                  />
                  <img
                    alt="..."
                    className="area-img add-animation"
                    src={
                      require("assets/img/presentation-page/ipad-left-img.jpg")
                    }
                  />
                  <img
                    alt="..."
                    className="info-img add-animation"
                    src={
                      require("assets/img/presentation-page/ipad-right-img.jpg")
                    }
                  />
                </div>
              </Col>
              <Col md="4">
                <div className="section-description">
                  <h3 className="title">Traffic Management Systems</h3>
                  <h6 className="category">Enterprise Solutions For Smart Cities</h6>
                  <h5 className="description">
                    We specialise in engineering solutions for enterprise scale traffic management systems.
                    We use the latest tools and technologies available to create a smart city through advance traffic management.
                    Artificial Intelligence, Machine Learning, Neural Networks, Image Processing, Object Detection, are a few
                    of many tools and tech that we make use of.
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionContent;

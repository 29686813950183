import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src={require("assets/img/presentation-page/a7.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "It took great team work and many months of hard work to get the system released to production.
            Thank you again!"
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Mark Hoffman</CardTitle>
            <h6 className=" card-category">Senior Specialist Business Analyst</h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "0",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src={require("assets/img/presentation-page/a6.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Today we as the leadership of the industry in the Western Cape are humbled and honoured to be celebrating
            the Blue Dot project here today. It's our flagship project."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Nazeem Abdurahman</CardTitle>
            <h6 className=" card-category">2nd Deputy Chairman - SANTACO Western Cape</h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "2",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className=" card-testimonial card-plain">
        <div className=" card-avatar">
          <img
            alt="..."
            className=" img"
            src={require("assets/img/presentation-page/a8.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5 className=" card-description">
            "Thanks for the update and efforts to date, much appreciated. Lets push for finality."
          </h5>
          <CardFooter>
            <CardTitle tag="h4">Nadeem Hendricks</CardTitle>
            <h6 className=" card-category">Deputy Director: Transport Systems</h6>
            <div className=" card-stars">
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star mr-1"></i>
              <i aria-hidden={true} className=" fa fa-star"></i>
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
];

function SectionTestimonials() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div
        className="section section-testimonials"
        id="testimonials"
      >
        <Container fluid>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">We're proud to be working with local governments and companies.</h2>
              <h5 className="description">
                We're always looking to help others achieve success. Through dedication and hard work we've managed to help companies reach higher tiers within their industry:
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="2">
              <div className="testimonials-people">
                <img
                  alt="..."
                  className="left-first-person add-animation"
                  src={require("assets/img/presentation-page/alan_winde.jpg")}
                />
                <img
                  alt="..."
                  className="left-second-person add-animation"
                  src={require("assets/img/presentation-page/a2.jpg")}
                />
                <img
                  alt="..."
                  className="left-third-person add-animation"
                  src={require("assets/img/presentation-page/315ddea260b49a185c16a5460f5928b7.jpg")}
                />
                <img
                  alt="..."
                  className="left-fourth-person add-animation"
                  src={require("assets/img/presentation-page/a1.jpg")}
                />
                <img
                  alt="..."
                  className="left-fifth-person add-animation"
                  src={require("assets/img/presentation-page/a3.jpg")}
                />
                <img
                  alt="..."
                  className="left-sixth-person add-animation"
                  src={require("assets/img/presentation-page/head_of_department_transport_and_public_works.jpg")}
                />
              </div>
            </Col>
            <Col md="6">
              <div className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item, key) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={key}
                      >
                        {item.content}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </div>
            </Col>
            <Col className="mr-auto" md="2">
              <div className="testimonials-people">
                <img
                  alt="..."
                  className="right-first-person add-animation"
                  src={require("assets/img/presentation-page/1517446813246.jpg")}
                />
                <img
                  alt="..."
                  className="right-second-person add-animation"
                  src={require("assets/img/presentation-page/a5.jpg")}
                />
                <img
                  alt="..."
                  className="right-third-person add-animation"
                  src={require("assets/img/presentation-page/a3.jpg")}
                />
                <img
                  alt="..."
                  className="right-fourth-person add-animation"
                  src={require("assets/img/presentation-page/1650439577032.jpg")}
                />
                <img
                  alt="..."
                  className="right-fifth-person add-animation"
                  src={require("assets/img/presentation-page/a1.jpg")}
                />
                <img
                  alt="..."
                  className="right-sixth-person add-animation"
                  src={require("assets/img/presentation-page/a4.jpg")}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="our-clients">
          <Container>
            <Row className="justify-content-center">
              <Col md="2" xs="5">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/download1.png")}
                />
              </Col>
              <Col md="2" xs="5">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/Transport Department1.jpg")}
                />
              </Col>
              <Col md="2" xs="5">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/RESOURGENIX-White-ali.png")}
                />
              </Col>
              <Col md="2" xs="5">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/Dimension Data.jpg")}
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default SectionTestimonials;

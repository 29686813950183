import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
// core components

function SectionSummary() {
  return (
    <>
      <div className="section section-dark section-summary">
        <Container>
          <Row>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-settings" />
                </div>
                <div className="description">
                  <h4 className="info-title">Sophisticated Back-end Systems</h4>
                  <p>
                    Our team has a wealth of experience and skill sets required to engineer sophisticated back-end systems
                    that are designed to integrate with bespoke hardware.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-mobile" />
                </div>
                <div className="description">
                  <h4 className="info-title">Modern, Highly Optimised Front-end Systems</h4>
                  <p>
                    We engineer modern progressive web applications that are able to run on very low tech devices such as
                    WAP enabled mobile phones with low latency internet connections, and high end tech devices such as modern desktop computers on fiber lines.
                  </p>
                </div>
              </div>
            </Col>
            <Col md="4">
              <div className="info">
                <div className="icon icon-danger">
                  <i className="nc-icon nc-cloud-download-93" />
                </div>
                <div className="description">
                  <h4 className="info-title">Cloud Development</h4>
                  <p>
                    Our cloud engineers use the latest available premium services offered by the major cloud providers such as Microsoft Azure and Amazon Web Services.
                    We engineer cloud environments for large enterprise clients as well as small product/service focused clients.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionSummary;
